<script>
import CustomTable from "@/components/tables/table.vue";
import OrderApi from '@/api/orders/orders_api.js'
import {ordersMehtods} from "@/state/helpers";
import Swal from "sweetalert2";
import PageHeader from "@/components/page-header.vue";
import OrderPreview from '@/views/pages/orders/container/modals/OrderPreview.vue'
import StatisticsByCompanies from "@/views/pages/orders/components/StatisticsByCompanies.vue";
import store from "@/state/store";
import moment from "moment";

export default {
  data() {
    return {
      title: 'Orders',
      items: [
        {
          text: 'Home',
          href: '/',
        },
        {
          text: 'Container Orders',
          active: true,
        },
      ],
      numberOfErrors: 0,
      headers: [
        {
          label: 'ORDER NUMBER',
          field: 'order_number',
          align: 'center',
          excel_data: (order) => {
            return order.order.order_number
          },
        },
        {
          label: 'LOT NUMBER',
          field: 'lot_number',
          align: 'center',
          excel_data: (order) => {
            return order.order.lot_number
          },
        },
        {
          label: 'Containers',
          field: 'containers',
          align: 'center',
          visible: false,
          excel_data: (order) => {
            return order.order.containers ? order.order.containers.join(', ') : '-'
          },
          searchable: false
        },
        {
          label: 'POSITION',
          field: 'position',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Multi modal', value: 'multi_modal'},
            {label: 'Block train', value: 'block_train'},
            {label: 'Rail forwarder', value: 'rail_forwarder'},
          ],
          excel_data: (order) => {
            return order.order.position
          },
        },
        {
          label: 'TASKS / FINANCE STATUS',
          field: 'finance_status',
          excel_data: (order) => {
            return order.finance_status.map(finance_status => finance_status.counterparty).join(', ')
          },
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Completed', value: 'true'},
            {label: 'Incomplete', value: 'false'},
          ],
        },
        {
          label: 'QUANTITY',
          field: 'quantity',
          excel_data: (order) => {
            return order.quantity || '-'
          },
          align: 'center',
          searchable: false
        },
        {
          label: 'AGREED RATE',
          field: 'total_agreed_rate',
          align: 'center',
          excel_data: (order) => {
            return order.order.total_agreed_rate
          },
        },
        {
          label: 'ORDER TYPE',
          field: 'type',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Import', value: 'import'},
            {label: 'Export', value: 'export'},
            {label: 'Transit', value: 'transit'},
          ],
          excel_data: (order) => {
            return order.order.type
          },
        },
        {
          label: 'INVOICE',
          field: 'invoice_status',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Paid', value: 'paid'},
            {label: 'Unpaid', value: 'unpaid'},
            {label: 'Expired invoices', value: 'expired'},
            {label: 'No invoice', value: 'not_issued'},
          ],
          excel_data: (order) => {
            return order.order.invoice ? 'Yes' : 'No'
          },
          align: 'center',
        },
        {
          label: 'COMPANY',
          field: 'company',
          excel_data: (order) => {
            return order.order.company ? order.order.company.name : '-'
          },
          align: 'center',
        },
        {
          label: 'PAYMENT STATUS',
          field: 'payment_status',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Reserved', value: 'reserved'},
            {label: 'Received', value: 'received'},
            {label: 'Issued', value: 'issued'},
          ],
          visible: false,
          excel_data: (order) => {
            return order.order.payment_status
          },
        },
        {
          label: 'SHIPMENT STATUS',
          field: 'shipment_status',
          align: 'center',
          searchType: 'select',
          searchOptions: [
            {label: 'All', value: ''},
            {label: 'Delivered', value: 'delivered'},
            {label: 'Completed', value: 'completed'},
            {label: 'In process', value: 'in_process'},
          ],
          excel_data: (order) => {
            return order.order.shipment_status
          },
          visible: false
        },
        {
          label: 'DATE',
          field: 'date',
          excel_data: (order) => {
            return order.order.date
          },
          align: 'center',
        },
        {
          label: 'MANAGER',
          field: 'user',
          excel_data: (order) => {
            return order.order.user ? order.order.user.username : '-'
          },
          align: 'center',
        },
        {
          label: 'COMMENT',
          field: 'comment',
          excel_data: (order) => {
            return order.order.comment
          },
          align: 'center',
          visible: false
        },
        {
          label: 'ACTIONS',
          field: 'actions',
          align: 'center',
        },
      ],
      orders: [],
      orderUrl: `${process.env.VUE_APP_ORDER_URL}/container_order/list/`,
      isLoading: false,

      table: {
        url: `/container_order/list/`,
      },
      widgets_url: `${process.env.VUE_APP_ORDER_URL}/container_order/statistic/`,
      pagination: {
        perPage: 100,
      },

      getUpdate: false,


      widgets: {
        url: `${process.env.VUE_APP_ORDER_URL}/container_order/statistic/`,
        list: [
          {
            label: 'Quantity',
            field: 'quantity',
            actual_volume: 0,
            current_volume: 0
          }
        ]
      },
      preview_order: {},

      order_company_ids: [],

      blacklisted_users_ids: []
    };
  },
  methods: {
    ...ordersMehtods,

    checkInvoiceDate(invoice_date) {
      const date = moment(invoice_date);
      const now = moment();

      return {
        expired: date.isBefore(now),
      };
    },

    async getOrders() {
      this.isLoading = true;
      let orderApi = new OrderApi();
      let data = await orderApi.getContainerOrders();
      let orders = []
      data.results.forEach(order => {
        order.order.product = order.product
        order.order.sending_type = order.sending_type
        orders.push(order.order)
      })
      this.orders = orders
      this.isLoading = false
    },
    setToUpdateOrder(order) {
      this.$router.push({name: 'orders_container_update', params: {id: order.order.order_number}})
    },
    deleteOrderConfirmation(order) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: `You are about to delete order ${(order.order_number).toString().toUpperCase()}`,
        text: 'Deleting your order will remove all of its information from our database.',
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, Delete It',
        denyButtonText: 'Cancel',
        cancelButtonColor: 'transparent',
        focusConfirm: false,
        inputLabel: `Please type Order${(order.order_number).toString().toUpperCase()} to confirm`,
        input: 'email',
        inputPlaceholder: `Order${(order.order_number).toString().toUpperCase()}`,
        inputValidator: (value) => {
          return new Promise((resolve) => {
            if (value === 'Order' + (order.order_number).toString().toUpperCase()) {
              resolve(this.deleteOrder(order))
            } else {
              resolve('Order number did not match :)')
            }
          })
        }
      });
    },

    deleteOrder(order) {
      fetch(`${process.env.VUE_APP_ORDER_URL}/container_order/list/${order.order_number}/delete/`, {
        method: 'DELETE',
      }).then(response => {
        if (response.ok) {
          this.getUpdate = !this.getUpdate
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Order has been deleted successfully',
            showConfirmButton: false,
            timer: 3000
          })
        } else {
          Swal.fire({
            position: 'center',
            icon: 'error',
            title: 'An error occurred while deleting order',
            showConfirmButton: false,
          })
        }
      }).catch(error => {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: `ERROR \n ${error}`,
          showConfirmButton: true,
          confirmButtonText: 'Try Again',
        }).then((result) => {
          if (result.isConfirmed) {
            if (this.numberOfErrors >= 2) {
              Swal.fire({
                position: 'center',
                icon: 'warning',
                title: `Too Many Tries...\nPlease, talk to IT department to fix the problem`,
                showConfirmButton: false,
                timer: 10000,
                willClose: () => {
                  window.location.reload()
                }
              })
            } else {
              this.numberOfErrors += 1
              this.deleteOrderConfirmation(order)
            }
          }
        })
      })
    },

    getAccount() {
      return {full_name: 'Unknown'}
    },

    pageChange(page) {
      this.pagination.currentPage = page
    },

    getTableData(data, field) {
      return data.row['order'][field]
    },

    downloadFile(file) {
      if (!file) return alert("Invalid File")
      let url = process.env.VUE_APP_ORDER_URL + file
      fetch(url)
          .then(resp => resp.blob())
          .then(blobobject => {
            const blob = window.URL.createObjectURL(blobobject);
            const anchor = document.createElement('a');
            anchor.style.display = 'none';
            anchor.href = blob;
            anchor.download = url.toString().split('/')[url.toString().split('/').length - 1];
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blob);
          })
          .catch(() => alert('An error in downloading the file sorry'));
    },
    onDataLoaded(data) {
      try {
        this.order_company_ids = []
        data.forEach(o => {
          if (!this.order_company_ids.includes(o.order.company.id)) {
            this.order_company_ids.push(o.order.company.id);
          }

          if (o.order.invoice) {
            if (o.order.invoice.status === 'paid') {
              o.order.invoice = {
                ...o.order.invoice,
                expired: false,
              }
            } else {
              o.order.invoice = {
                ...o.order.invoice,
                ...this.checkInvoiceDate(o.order.invoice.deadline)
              }
            }
          }
        });
      } catch {
        this.order_company_ids = []
      }
    }
  },
  computed: {
    headerComputed: {
      get() {
        return this.headers
      }
    },
    user() {
      return store.state.user
    }
  },
  async mounted() {
    // await this.getOrders()
    //
    if (this.blacklisted_users_ids.includes(this.user.id)) {
      this.headers = this.headers.filter(h => h.field !== 'total_agreed_rate')
    }
  },
  components: {
    CustomTable,
    PageHeader,
    OrderPreview,
    StatisticsByCompanies
  },
};
</script>

<template>
  <PageHeader :title="title" :items="items"/>
  <div class="mb-4">
    <CustomTable
        ref="orders_table"
        name="ORDERS TABLE"
        id="orders_table"
        :headers="headerComputed"
        :selectable="true"
        :searchable="true"
        :url="table.url"
        :getUpdate="getUpdate"
        @on-loaded="onDataLoaded($event)"
    >
      <template v-slot:top-right>
        <router-link :to="{name: 'create_container_order'}">
          <button type="button" class="btn btn-success">
            Create container order
          </button>
        </router-link>
      </template>

      <template v-slot:header_div>
        <div class="mt-3">
          <StatisticsByCompanies :company_ids="order_company_ids"/>
        </div>
      </template>

      <template v-slot:quantity="{row: order}">
        <VTooltip>
        <span class="badge" :class="{
        'badge-outline-success' : order.order.filled_quantity === order.order.quantity,
        'badge-outline-danger' : order.order.filled_quantity < order.order.quantity,
      }">
          {{ order.order.filled_quantity + '/' + order.order.quantity }}
      </span>
          <template v-slot:popper>
            {{ order.order.filled_quantity || 0 }} rows filled out of {{ order.order.quantity || 0 }}
          </template>
        </VTooltip>
      </template>

      <template v-slot:order_number="slotProps">
        <span class="badge badge-soft-secondary fs-12">
          <router-link :to="{name: 'orders_container_detail', params: {id: getTableData(slotProps, 'order_number')}}">
            {{ getTableData(slotProps, 'order_number') }}
          </router-link>
        </span>
      </template>

      <template v-slot:invoice_status="{row: order}">
        <div
            v-if=" order.order.invoice"
            @click="downloadFile(order.order.invoice.file)"
        >
          <div :class="{
               'text-success': order.order.invoice.status === 'paid',
               'text-danger fw-bold': order.order.invoice.status === 'canceled' || order.order.invoice.expired,
               'text-warning': order.order.invoice.status === 'unpaid',
             }"
               v-b-tooltip :title="order.order.invoice.status"
               class="cursor-pointer"
          >
            <i class="ri-download-2-fill align-middle me-1"></i>
            {{ order.order.invoice.number }}
          </div>
          <small class="text-muted">
            {{ order.order.invoice.deadline ? 'Due ' + order.order.invoice.deadline : '' }}
          </small>
        </div>
        <div v-else>
          <router-link v-if="order.order && order.order.order_number"
                       :to="{name:'container_invoice_create', params: {order_number: order.order.order_number }}">
            <i class="bx bx-plus-circle fs-5" v-b-tooltip.hover title="Create an invoice"></i>
          </router-link>
          <span v-else>-</span>
        </div>
      </template>

      <template v-slot:actions="slotProps">

        <b-button-group class="mt-4 mt-md-0" role="group" size="sm" aria-label="Basic example">
          <b-button variant="light"
                    @click="preview_order = slotProps.row"
                    data-bs-toggle="modal"
                    data-bs-target="#container_order_preview_modal">
            <i class="ri-eye-fill align-bottom fs-6"></i></b-button>
          <b-button variant="light" class="p-0" @click="setToUpdateOrder(slotProps.row)">
            <div class="px-1">
              <i class="ri-pencil-fill align-bottom px-1"></i>
            </div>
          </b-button>
          <b-button variant="light" @click="deleteOrderConfirmation(slotProps.row.order)">
            <i class="ri-delete-bin-7-fill align-bottom text-danger"></i>
          </b-button>
        </b-button-group>

      </template>

      <template v-slot:company="slotProps">
        <span v-if="slotProps.row.order.company === null">--</span>
        <div v-else>
          <router-link
              :to="{name: 'customer_profile', params: {slug: slotProps.row.order.company.slug}}">
                    <span class="rounded-circle bg-soft-secondary text-secondary mx-1 px-2">
          {{ (slotProps.row.order.company.name.slice(0, 1)) }}
        </span>
            <span>
          {{ (slotProps.row.order.company.name) }}
        </span>
          </router-link>
        </div>
      </template>

      <template v-slot:user="{row: {order: order}}">
        <template v-if="order.user">
          <router-link :to="{name: 'user_profile', params: {slug: order.user.slug}}">
            <div class="d-flex align-items-center justify-content-center">
              <div class="avatar-xxs flex-shrink-0 me-1">
        <span class="avatar-title bg-light text-primary rounded-circle">
          {{ order.user.username[0].toUpperCase() }}
        </span>
              </div>
              <span>{{ order.user.username }}</span>
            </div>
          </router-link>
        </template>
        <span v-else>--</span>
      </template>

      <template v-slot:type="slotProps">
      <span v-if="getTableData(slotProps, 'type') == 'Export'" class="badge bg-success">
        Export
      </span>
        <span v-else-if="getTableData(slotProps, 'type') == 'Import'" class="badge bg-primary">
        Import
      </span>
        <span v-else-if="getTableData(slotProps, 'type') == 'Transit'" class="badge bg-warning">
        Transit
      </span>
      </template>

      <template v-slot:shipment_status="slotProps">
      <span v-if="getTableData(slotProps, 'shipment_status') == 'Completed'" class="badge badge-outline-success">
        Completed
      </span>
        <span v-else-if="getTableData(slotProps, 'shipment_status') == 'Delivered'"
              class="badge badge-outline-primary">
        Delivered
      </span>
        <span v-else-if="getTableData(slotProps, 'shipment_status') == 'In process'"
              class="badge badge-outline-warning">
        In process
      </span>
      </template>

      <template v-slot:payment_status="slotProps">
      <span v-if="getTableData(slotProps, 'payment_status') == 'Reserved'" class="badge badge-outline-success">
        Reserved
      </span>
        <span v-else-if="getTableData(slotProps, 'payment_status') == 'Received'"
              class="badge badge-outline-primary">
        Received
      </span>
        <span v-else-if="getTableData(slotProps, 'payment_status') == 'Issued'" class="badge badge-outline-warning">
        Issued
      </span>
      </template>

      <template v-slot:lot_number="slotProps">
        {{
          getTableData(slotProps, 'lot_number') === null || getTableData(slotProps, 'lot_number') === '' ? '-'
              : getTableData(slotProps, 'lot_number')
        }}
      </template>

      <template v-slot:position="slotProps">
        {{
          slotProps.row.order.position === null || slotProps.row.order.position === ''
              ? '-'
              : slotProps.row.order.position
        }}
      </template>

      <template v-slot:finance_status="{row: order}">
        <router-link
            v-if="order.order.task_project"
            :to="{name: 'project_tasks_list', params: {
          project_id: order.order.task_project.id
        }}">
          <BProgress v-b-tooltip.hover
                     :title="`${order.order.task_project.task_completed_count} out of ${order.order.task_project.task_count} task completed  ✅`"
                     :value="((order.order.task_project.task_completed_count * 100) / order.order.task_project.task_count )"
                     variant="success" class="mb-1"></BProgress>
        </router-link>

        <b-progress>
          <b-progress-bar v-for="(counterparty, index) of order.finance_status"
                          :key="index"
                          :value="100 / (order.finance_status.length)"
                          v-b-tooltip.hover
                          :title="counterparty.counterparty"
                          class="border-end"
                          :variant="counterparty.status ? 'success' : 'danger'"
          />
        </b-progress>
      </template>

      <template v-slot:date="slotProps">
        {{ slotProps.row.order.date }}
      </template>

      <template v-slot:total_agreed_rate="{row: order}">
        <div v-if="order.order.total_agreed_rate">
          ${{
            parseFloat(order.order.total_agreed_rate).toLocaleString(undefined, {
              minimumFractionDigits: 1, maximumFractionDigits: 2
            })
          }}

          <span class="text-danger"
                v-b-tooltip.hover title="Invoice price"
                v-if="order.order.invoice && (order.order.invoice ? order.order.invoice.total_cost : 0) !== parseFloat(order.order.total_agreed_rate || 0)">
            ({{
              parseFloat(order.order.invoice ? order.order.invoice.total_cost || 0 : 0).toLocaleString(undefined, {
                minimumFractionDigits: 1, maximumFractionDigits: 2
              })
            }})
          </span>

        </div>
        <span v-else>--</span>
      </template>

      <template v-slot:comment="slotProps">
        {{ slotProps.row.order.comment }}
      </template>

      <template v-slot:containers="{row: order}">
        <VTooltip v-if="order.containers">
          <span class="fs-10">
            {{ order.containers.length > 0 ? order.containers.slice(0, 5).join(', ') : ' --' }}
          </span>
          <template #popper>
            <!--            <b-card no-body class="pb-0">-->
            <!--              <b-card-body class="pb-0 mb-0">-->
            <table class="table table-nowrap">
              <tbody>
              <tr v-for="(container, i) in order.containers" :key="container">
                <th scope="row">
                  <a href="#" class="fw-semibold">{{ i + 1 }}</a>
                </th>
                <td class="text-light fw-bold">{{ container }}</td>
              </tr>
              </tbody>
            </table>
            <!--              </b-card-body>/-->
            <!--            </b-card>-->
          </template>
        </VTooltip>
        <span v-else>--</span>
      </template>

    </CustomTable>
  </div>

  <OrderPreview :order="preview_order"/>
</template>